<template>
  <a-modal
    title="添加/编辑事件"
    :width="800"
    :destroyOnClose="true"
    :confirmLoading="addloading"
    :visible="visible"
    @ok="saveEventApi"
    @cancel="cancelForm"
  >
    <a-form :form="form">
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="事件标题"
        :validateStatus="checkParams.titleStatus"
        :help="checkParams.titleStatusMsg"
      >
        <a-input
          placeholder="请输入事件标题"
          v-decorator="['title', {initialValue: eventDealData.title, rules: [{required: true, validator: titleCheck, validateTrigger: 'change'}]}]"
        />
      </a-form-item>
      <a-form-item
        style="margin-bottom: 0px;"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="所属小区"
        :required="true"
      >
        <a-row :gutter="24">
          <a-col :md="12" :sm="8">
            <a-form-item
              :validateStatus="checkParams.eventCountyStatus"
              :help="checkParams.eventCountyStatusMsg"
            >
              <a-cascader
                style="width:248px;"
                :options="regionList"
                placeholder="省-市-区(县)"
                :field-names="{label: 'label', value: 'value', children: 'children' }"
                :value="defaultRegion"
                @change="changeRegion"
              />
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="8">
            <a-form-item
              :validateStatus="checkParams.communityIdStatus"
              :help="checkParams.communityIdStatusMsg"
            >
              <a-select
                style="width:238px;"
                v-model="eventDealData.communityId"
                placeholder="请选择小区"
                allow-clear
                @change="changeCommunity"
              >
                <a-select-option :key="0" :value="0">请选择小区</a-select-option>
                <a-select-option v-for="item in regionCommunity" :key="item.keyId" :value="item.id">
                  {{ item.communityName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item
        style="margin-bottom: 0px;"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="发生地点"
        :required="true"
      >
        <a-row :gutter="24">
          <a-col :md="14" :sm="8">
            <a-form-item
              :validateStatus="checkParams.eventPlaceStatus"
              :help="checkParams.eventPlaceStatusMsg"
            >
              <a-input
                placeholder="请输入发生地点"
                v-decorator="['eventPlace', {initialValue: eventDealData.eventPlace, rules: [{required: true, validator: eventPlaceCheck, validateTrigger: 'change'}]}]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="10" :sm="8">
            <a-form-item>
              <a-input
                placeholder="详细地址"
                v-model="eventDealData.placeDetail"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        :validateStatus="checkParams.eventTimeStatus"
        :help="checkParams.eventTimeStatusMsg"
        label="发生时间"
        :required="true"
      >
        <a-date-picker
          v-model="eventDealData.eventTime"
          style="width: 180px;"
          showTime
          valueFormat="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          @change="changeEventDealData"
        />
      </a-form-item>
      <a-form-item
        style="margin-bottom: 0px;"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="事件分类"
        :required="true"
      >
        <a-row :gutter="24">
          <a-col :md="6" :sm="8">
            <a-form-item
              :validateStatus="checkParams.eventGroupStatus"
              :help="checkParams.eventGroupStatusMsg"
            >
              <a-select
                style="width:120px;"
                placeholder="请选择分类"
                v-model="eventDealData.eventGroup"
                allow-clear
                @change="changeEventGroup"
              >
                <a-select-option :key="0" :value="0">请选择分类</a-select-option>
                <a-select-option v-for="item in eventGroup" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="9" :sm="8">
            <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              :validateStatus="checkParams.eventSourceStatus"
              :help="checkParams.eventSourceStatusMsg"
              label="来源"
            >
              <a-select
                style="width:135px;"
                v-model="eventDealData.eventSource"
                placeholder="请选择来源"
                allow-clear
                @change="changeEventSource"
              >
                <a-select-option :key="0" :value="0">请选择来源</a-select-option>
                <a-select-option v-for="item in eventSourceList" :key="item.keyId" :value="item.itemId">
                  {{ item.itemName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item
        style="margin-bottom: 0px;"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="类型"
        :required="true"
      >
        <a-row :gutter="24">
          <a-col :md="6" :sm="8">
            <a-form-item
              :validateStatus="checkParams.eventTypeStatus"
              :help="checkParams.eventTypeStatusMsg"
            >
              <a-select
                style="width:120px;"
                placeholder="请选择类型"
                v-model="eventDealData.eventType"
                allow-clear
                @change="changeEventType"
              >
                <a-select-option :key="0" :value="0">请选择类型</a-select-option>
                <a-select-option v-for="item in eventTypeList" :key="item.keyId" :value="item.itemId">
                  {{ item.itemName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="9" :sm="8">
            <a-form-item
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
              :validateStatus="checkParams.eventLevelStatus"
              :help="checkParams.eventLevelStatusMsg"
              label="级别"
            >
              <a-select
                style="width:150px;"
                v-model="eventDealData.eventLevel"
                placeholder="请选择级别"
                allow-clear
                @change="changeEventLevel"
              >
                <a-select-option :key="0" :value="0">请选择级别</a-select-option>
                <a-select-option v-for="item in eventLevelList" :key="item.keyId" :value="item.itemId">
                  {{ item.itemName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="上报人身份"
        v-if="eventDealData.eventGroup === 'HealthEventRecord'"
      >
        <a-input
          placeholder="请输入上报人身份"
          v-model="eventDealData.reporterIdentity"
        />
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="上报人电话"
        v-if="eventDealData.eventGroup === 'HealthEventRecord'"
      >
        <a-input
          placeholder="请输入上报人电话"
          v-model="eventDealData.reporterPhone"
        />
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="上报内容"
      >
        <a-textarea :rows="5" v-model="eventDealData.desc" placeholder="上报内容" />
      </a-form-item>
      <a-form-item
        style="margin-bottom: 0px;"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="图片"
      >
        <a-upload
          :fileList="fileList"
          listType="picture-card"
          :disabled="isImageUpload"
          :beforeUpload="beforeUpload"
          :customRequest="customRequest"
          @preview="handlePreview"
          :remove="removeImage"
          accept="image/*"
        >
          <div>
            <span><a-icon :type="uploading ? 'loading' : 'picture'" /></span>
            <span>上传</span>
          </div>
        </a-upload>
        <a-modal :visible="previewInfo.previewVisible" :title="previewInfo.previewTitle" :footer="null" @cancel="handleCancel">
          <img alt="example" style="width: 100%" :src="previewInfo.previewImage" />
        </a-modal>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>

import OSS from 'ali-oss'
import { addImagelibrary, getToken } from '@/api/upload'
import { eventCondition, saveEvent } from '@/api/event'
import { regionList } from '@/api/region'
import { regionCommunity } from '@/api/community'

export default {
  name: 'DealEvent',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'addEventForm' }),
      addloading: false,
      dealCheckStatus: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      loading: false,
      queryParam: {
        id: '',
        keyId: 0,
        status: -1,
        desc: '',
        imageList: []
      },
      previewInfo: {
        previewVisible: false,
        previewTitle: '',
        previewImage: ''
      },
      fileList: [],
      isImageUpload: false,
      uploading: false,
      uploadImageInfo: {
        fileName: '',
        url: '',
        width: 0,
        height: 0,
        size: 0,
        bucket: 'dadaojianqi-syue',
        type: 0,
        transcoding: 0
      },
      tokenInfo: {
        'region': '',
        'AccessKeyId': '',
        'AccessKeySecret': '',
        'SecurityToken': '',
        'bucket': '',
        'domain': ''
      },
      checkParams: {
        statusStatus: 'success',
        statusStatusMsg: null,
        titleStatus: 'success',
        titleStatusMsg: null,
        communityIdStatus: 'success',
        communityIdStatusMsg: null,
        eventCountyStatus: 'success',
        eventCountyStatusMsg: null,
        eventTypeStatus: 'success',
        eventTypeStatusMsg: null,
        eventLevelStatus: 'success',
        eventLevelStatusMsg: null,
        eventSourceStatus: 'success',
        eventSourceStatusMsg: null,
        eventPlaceStatus: 'success',
        eventPlaceStatusMsg: null,
        eventTimeStatus: 'success',
        eventTimeStatusMsg: null,
        eventGroupStatus: 'success',
        eventGroupStatusMsg: null
      },
      eventDealData: {},
      statusList: [],
      eventLevelList: [],
      eventSourceList: [],
      eventSourceList1: [],
      eventSourceList2: [],
      eventTypeList: [],
      regionList: [],
      defaultRegion: [],
      regionCommunity: [],
      eventGroup: []
    }
  },
  created () {
  },
  methods: {
    initialData (data) {
      this.eventDealData = Object.assign({}, data)
      console.log('eventDealData', this.eventDealData)
      this.getTokenApi()
      this.eventConditionApi()
      this.regionListApi()
      if (this.eventDealData.eventCounty !== '') {
        this.defaultRegion = [this.eventDealData.eventProvince, this.eventDealData.eventCity, this.eventDealData.eventCounty]
        this.regionCommunityApi(this.eventDealData.eventCounty)
      } else {
        this.defaultRegion = []
      }
      this.fileList = this.eventDealData.attachmentsList
      console.log(this.fileList)
    },
    closeSeeInfo () {
      this.visible = false
    },
    cancelForm () {
      this.checkParams.statusStatus = 'success'
      this.checkParams.statusStatusMsg = null
      this.$emit('cancel', 1)
    },
    handleCancel (e) {
      console.log(e)
      this.previewInfo.previewVisible = false
      this.previewInfo.previewImage = ''
      this.previewInfo.previewTitle = ''
    },
    saveEventApi () {
      const _this = this
      console.log('saveDealEvent', _this.eventDealData)
      if (_this.eventDealData.title.trim() === '') {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入事件标题'
        return false
      } else {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      }

      if (_this.eventDealData.eventCounty === 0 || _this.eventDealData.eventCounty === '') {
        _this.checkParams.eventCountyStatus = 'error'
        _this.checkParams.eventCountyStatusMsg = '请选择区域'
        return false
      } else {
        _this.checkParams.eventCountyStatus = 'success'
        _this.checkParams.eventCountyStatusMsg = null
      }

      if (_this.eventDealData.communityId === 0 || _this.eventDealData.communityId === undefined) {
        _this.checkParams.communityIdStatus = 'error'
        _this.checkParams.communityIdStatusMsg = '请选择小区'
        return false
      } else {
        _this.checkParams.communityIdStatus = 'success'
        _this.checkParams.communityIdStatusMsg = null
      }

      if (_this.eventDealData.eventPlace.trim() !== '') {
        _this.checkParams.eventPlaceStatus = 'success'
        _this.checkParams.eventPlaceStatusMsg = null
      } else {
        _this.checkParams.eventPlaceStatus = 'error'
        _this.checkParams.eventPlaceStatusMsg = '请输入事件发生地点'
        return false
      }

      if (_this.eventDealData.eventTime === '' || _this.eventDealData.eventTime === null) {
        _this.checkParams.eventTimeStatus = 'error'
        _this.checkParams.eventTimeStatusMsg = '请选择事件发生时间'
        return false
      } else {
        _this.checkParams.eventTimeStatus = 'success'
        _this.checkParams.eventTimeStatusMsg = null
      }

      if (_this.eventDealData.eventGroup === 0 || _this.eventDealData.eventGroup === undefined) {
        _this.checkParams.eventGroupStatus = 'error'
        _this.checkParams.eventGroupStatusMsg = '请选择事件分类'
        return false
      } else {
        _this.checkParams.eventGroupStatus = 'success'
        _this.checkParams.eventGroupStatusMsg = null
      }

      if (_this.eventDealData.eventSource === 0 || _this.eventDealData.eventSource === undefined) {
        _this.checkParams.eventSourceStatus = 'error'
        _this.checkParams.eventSourceStatusMsg = '请选择事件来源'
        return false
      } else {
        _this.checkParams.eventSourceStatus = 'success'
        _this.checkParams.eventSourceStatusMsg = null
      }

      if (_this.eventDealData.eventType === 0 || _this.eventDealData.eventType === undefined) {
        _this.checkParams.eventTypeStatus = 'error'
        _this.checkParams.eventTypeStatusMsg = '请选择事件类型'
        return false
      } else {
        _this.checkParams.eventTypeStatus = 'success'
        _this.checkParams.eventTypeStatusMsg = null
      }

      if (_this.eventDealData.eventLevel === 0 || _this.eventDealData.eventLevel === undefined) {
        _this.checkParams.eventLevelStatus = 'error'
        _this.checkParams.eventLevelStatusMsg = '请选择事件级别'
        return false
      } else {
        _this.checkParams.eventLevelStatus = 'success'
        _this.checkParams.eventLevelStatusMsg = null
      }

      saveEvent(_this.eventDealData).then((res) => {
        if (res.errorCode === 0) {
          if (_this.eventDealData.keyId > 0) {
            _this.$message.success('编辑成功')
          } else {
            _this.$message.success('添加成功')
          }
          this.$emit('ok', 1)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handlePreview (e) {
      this.previewInfo.previewImage = e.url
      this.previewInfo.previewTitle = e.name
      this.previewInfo.previewVisible = true
      console.log('handlePreview', e)
    },
    removeImage (e) {
      console.log('removeImage', e)
      this.fileList.forEach((item, index) => {
        if (item.uid === e.uid) {
          this.fileList.splice(index, 1)
        }
      })

      var indexa = this.eventDealData.attachments.indexOf(e.url)
      if (indexa > -1) {
        this.eventDealData.attachments.splice(indexa, 1)
      }
      return true
    },
    getTokenApi () {
      const _this = this
      getToken().then((res) => {
        if (res.errorCode === 0) {
          _this.tokenInfo = res.result
          console.log('tokenInfo', _this.tokenInfo)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
      })
    },
    addImagelibraryApi () {
      const _this = this
      addImagelibrary(_this.uploadImageInfo).then((res) => {
        if (res.errorCode === 0) {
          const imgRow = {
            uid: res.result.id,
            name: res.result.fileName,
            status: 'done',
            url: res.result.url
          }
          _this.fileList.push(imgRow)
          _this.eventDealData.attachments.push(res.result.url)
          console.log('fileList', _this.fileList)
          _this.$message.success('上传成功')
          console.log('addImagelibrary', res.result)
        } else {
          _this.$message.error(res.errorMsg)
        }
        _this.isImageUpload = false
        _this.uploading = false
      }).catch((err) => {
        _this.isImageUpload = false
        _this.uploading = false
        _this.$message.error('系统错误')
        console.log(err)
      })
    },
    async customRequest (e) {
      console.log('customRequest', e)
      const _this = this
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        region: _this.tokenInfo.region,
        // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
        accessKeyId: _this.tokenInfo.AccessKeyId,
        accessKeySecret: _this.tokenInfo.AccessKeySecret,
        stsToken: _this.tokenInfo.SecurityToken,
        // 填写Bucket名称，例如examplebucket。
        bucket: _this.tokenInfo.bucket,
        secure: true
      })

      const headers = {
        // 指定该Object被下载时的网页缓存行为。
        'Cache-Control': 'no-cache',
        // 指定该Object被下载时的名称。
        'Content-Disposition': _this.uploadImageInfo.fileName,
        // 指定该Object被下载时的内容编码格式。
        'Expires': 1000,
        'Content-Encoding': 'utf-8',
        // 指定Object标签，可同时设置多个标签。
        'x-oss-tagging': 'image=1',
        // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
        'x-oss-forbid-overwrite': 'true'
      }

      const options = {
        // 获取分片上传进度、断点和返回值。
        progress: (p, cpt, res) => {
          console.log(p)
        },
        // 设置并发上传的分片数量。
        parallel: 4,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024,
        headers
      }
      console.log(client, options)

      try {
        const result = await client.multipartUpload(_this.uploadImageInfo.fileName, e.file, {
          ...options
        })

        if (result.name) {
          console.log(_this.tokenInfo.domain + result.name)
          _this.uploadImageInfo.url = _this.tokenInfo.domain + result.name
          _this.addImagelibraryApi()
        } else {
          _this.$message.error('上传失败')
          _this.isImageUpload = false
          _this.uploading = false
        }
        console.log(result)
      } catch (e) {
        _this.isImageUpload = false
        _this.uploading = false
        console.log(e)
      }
    },
    beforeUpload (file, fileList) {
      console.log('beforeUpload', file)
      if (this.isImageUpload) {
        this.$message.error('有其他图片正常上传，请稍后在操作')
        return false
      }
      console.log(file)
      var fileArr = file.name.split('.')
      var fileType = fileArr[fileArr.length - 1]

      var myDate = new Date()
      var year = myDate.getFullYear()
      var month = myDate.getMonth() + 1
      var day = myDate.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }

      if (day >= 0 && day <= 9) {
        day = '0' + day
      }

      var randomStr = this.getRandomStr(14)
      var keyimg = 'image/' + year + '/' + month + day + '/' + randomStr + '.' + fileType
      console.log(keyimg)
      this.uploadImageInfo.fileName = keyimg
      this.uploadImageInfo.size = Math.ceil(file.size / 1000)
      this.isImageUpload = true
      this.uploading = true
      return true
    },
    getRandomStr (len) {
      var str = ''
      for (var i = 0; i < len; i++) {
        str += Math.random().toString(36).substr(2)
      }
      return str.substr(0, len)
    },
    changeStatus (e) {
      var status = parseInt(e)
      if (status === -1) {
        this.checkParams.statusStatus = 'error'
        this.checkParams.statusStatusMsg = '请选择处理状态'
      } else {
        this.checkParams.statusStatus = 'success'
        this.checkParams.statusStatusMsg = null
      }
    },
    changeCommunity (e) {
      console.log('changeCommunity', e)
      if (e === 0 || e === undefined) {
        this.checkParams.communityIdStatus = 'error'
        this.checkParams.communityIdStatusMsg = '请选择小区'
      } else {
        this.checkParams.communityIdStatus = 'success'
        this.checkParams.communityIdStatusMsg = null
      }
    },
    changeEventType (e) {
      console.log('changeEventType', e)
      if (e === 0 || e === undefined) {
        this.checkParams.eventTypeStatus = 'error'
        this.checkParams.eventTypeStatusMsg = '请选择事件类型'
      } else {
        this.checkParams.eventTypeStatus = 'success'
        this.checkParams.eventTypeStatusMsg = null
      }
    },
    changeEventGroup (e) {
      console.log('changeEventType', e)
      if (e === 0 || e === undefined) {
        this.checkParams.eventGroupStatus = 'error'
        this.checkParams.eventGroupStatusMsg = '请选择事件分类'
      } else {
        if (e === 'EventRecord') {
          this.eventSourceList = this.eventSourceList1
        } else {
          this.eventSourceList = this.eventSourceList2
        }
        this.eventDealData.eventSource = 0
        this.checkParams.eventGroupStatus = 'success'
        this.checkParams.eventGroupStatusMsg = null
      }
    },
    changeEventLevel (e) {
      console.log('changeEventLevel', e)
      if (e === 0 || e === undefined) {
        this.checkParams.eventLevelStatus = 'error'
        this.checkParams.eventLevelStatusMsg = '请选择事件级别'
      } else {
        this.checkParams.eventLevelStatus = 'success'
        this.checkParams.eventLevelStatusMsg = null
      }
    },
    changeEventSource (e) {
      console.log('changeEventSource', e)
      if (e === 0 || e === undefined) {
        this.checkParams.eventSourceStatus = 'error'
        this.checkParams.eventSourceStatusMsg = '请选择事件来源'
      } else {
        this.checkParams.eventSourceStatus = 'success'
        this.checkParams.eventSourceStatusMsg = null
      }
    },
    titleCheck (rule, value, callback) {
      const _this = this
      _this.eventDealData.title = value.trim()
      if (_this.eventDealData.title !== '') {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      } else {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入事件标题'
        callback(new Error('请输入事件标题'))
      }
    },
    eventPlaceCheck (rule, value, callback) {
      const _this = this
      _this.eventDealData.eventPlace = value.trim()
      if (_this.eventDealData.eventPlace !== '') {
        _this.checkParams.eventPlaceStatus = 'success'
        _this.checkParams.eventPlaceStatusMsg = null
      } else {
        _this.checkParams.eventPlaceStatus = 'error'
        _this.checkParams.eventPlaceStatusMsg = '请输入事件发生地点'
        callback(new Error('请输入事件发生地点'))
      }
    },
    eventConditionApi () {
      const _this = this
      eventCondition().then((res) => {
        if (res.errorCode === 0) {
          _this.statusList = res.result.statusList
          _this.eventLevelList = res.result.eventLevelList
          if (_this.eventDealData.eventGroup === 'EventRecord') {
            _this.eventSourceList = res.result.eventSourceList1
          } else {
            _this.eventSourceList = res.result.eventSourceList2
          }
          _this.eventSourceList1 = res.result.eventSourceList1
          _this.eventSourceList2 = res.result.eventSourceList2
          _this.eventTypeList = res.result.eventTypeList
          _this.eventGroup = res.result.eventGroup
          console.log('eventCondition', res)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionListApi () {
      const _this = this
      regionList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionList = res.result
          console.log('regionList', this.regionList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeRegion (e) {
      console.log('changeRegion', e)
      this.defaultRegion = e
      this.eventDealData.eventProvince = e[0] ? e[0] : 0
      this.eventDealData.eventCity = e[1] ? e[1] : 0
      this.eventDealData.eventCounty = e[2] ? e[2] : 0
      this.regionCommunityApi(this.eventDealData.eventCounty)
      this.eventDealData.communityId = 0
      if (this.eventDealData.eventCounty === 0) {
        this.checkParams.eventCountyStatus = 'error'
        this.checkParams.eventCountyStatusMsg = '请选择区域'
      } else {
        this.checkParams.eventCountyStatus = 'success'
        this.checkParams.eventCountyStatusMsg = null
      }
    },
    regionCommunityApi (areaCode) {
      const _this = this
      regionCommunity({ areaCode: areaCode }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.regionCommunity = res.result.data
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeEventDealData (e) {
      if (e === '' || e === null) {
        this.checkParams.eventTimeStatus = 'error'
        this.checkParams.eventTimeStatusMsg = '请选择事件发生时间'
      } else {
        this.checkParams.eventTimeStatus = 'success'
        this.checkParams.eventTimeStatusMsg = null
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
